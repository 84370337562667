import { template as template_c3cfc4a0d1a343eaa0d59832237be627 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c3cfc4a0d1a343eaa0d59832237be627(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
