import { template as template_817ce319e45b45d190227fcbfc712160 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_817ce319e45b45d190227fcbfc712160(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
