import { template as template_9eaafa2af0224ed18d38c1a88c7ac868 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_9eaafa2af0224ed18d38c1a88c7ac868(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
